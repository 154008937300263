import DefaultStyle from 'lowcode/common/DefaultStyle';
import EditorImg from 'lowcode/components/EditorImg';
import styles from './index.module.scss';

export default function Content({ id, data, attr = {} }: any) {
    const { title = '', list = [] } = data;
    const trimTitle = title.trim();

    return (
        <div
            className={`${styles.content} temp-hide temp-${id} GtmModuleData-section`}
            id={id}
            data-section-id="editor-RewardLists"
            data-section-id2={id}
            data-section-classname=".RewardLists-title"
        >
            <DefaultStyle id={id} attr={attr} />
            {trimTitle && <div className="RewardLists-title">{trimTitle}</div>}
            <div className="RewardLists-in">
                {list?.map((item: any, index: number) => {
                    const { title, link, pc, h5 } = item;
                    return (
                        <div className={`RewardLists-item`} key={index}>
                            <EditorImg pc={pc} h5={h5} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
